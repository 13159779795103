import { AccountBalance, Balance, HomeOutlined, SwapHorizontalCircleOutlined } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function Footer() {

    const navigate = useNavigate();

    return (
        <footer>
            <Paper sx={{ px: 2, border: 'none', boxShadow: 'none', backgroundColor: 'transparent', position: 'fixed', bottom: 10, left: 0, right: 0 }}>
                <BottomNavigation
                    sx={{ borderRadius: 30, backgroundColor: '#00baf2', height: 70,  }}
                    showLabels
                >
                    <BottomNavigationAction sx={{ color: '#fff'}} onClick={() => navigate('/home')} label="Home" icon={<HomeOutlined />} />
                    {/* <BottomNavigationAction sx={{ color: '#fff'}} onClick={() => navigate('/transactions')} label="Transactions" icon={<SwapHorizontalCircleOutlined />} /> */}
                    <BottomNavigationAction sx={{ borderRadius: 15, color: '#042e6f !important', bgcolor: 'white', p: 5, bottom: 5 }} onClick={() => navigate('/mutual-fund-plus')} icon={<SwapHorizontalCircleOutlined fontSize="large" />} />
                    {/* <BottomNavigationAction sx={{ color: '#fff'}} onClick={() => navigate('/assets')} label="Accounts" icon={<AccountBalance />} /> */}
                    <BottomNavigationAction sx={{ color: '#fff'}} onClick={() => navigate('/mutual-funds')} label="Portfolio" icon={<Balance />} />
                </BottomNavigation>
            </Paper>
        </footer>
    )
}