import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAccount, IMutualFund } from '../../dtos/dto';
import { fetchAccounts, fetchMutualFund } from '../action';

export interface AccountState {
    accounts: IAccount[];
    mutualFunds: IMutualFund[];
    mutualFundStatus: 'init' | 'idle' | 'loading' | 'failed';
    status: 'init' | 'idle' | 'loading' | 'failed';
}

const initialState: AccountState = {
    accounts: [],
    mutualFunds: [],
    status: 'init',
    mutualFundStatus: 'init'
};

export const updateAccountList = createAsyncThunk(
    'accountList',
    async (token: string) => {
        return await fetchAccounts(token);
    }
);

export const updateMutualFundList = createAsyncThunk(
    'mutualFundList',
    async (token: string) => {
        return await fetchMutualFund(token);
    }
);

export const accountSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        filterAccount: (state, action) => {
            state.accounts = state.accounts?.filter( x => x.id !== action.payload);
        },
        updateAccount: (state, action) => {
            state.accounts = state.accounts?.map( x => x.id !== action.payload.id ? x : action.payload);
        },
        addAccount: (state, action) => {
            state.accounts = state.accounts?.concat([action.payload]);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateMutualFundList.pending, (state) => {
                state = { ...state, mutualFundStatus: 'loading'};
            })
            .addCase(updateMutualFundList.fulfilled, (state, action) => {
                state.mutualFunds = action.payload;
                state.mutualFundStatus = 'idle';
            })
            .addCase(updateMutualFundList.rejected, (state) => {
                state = { ...state, mutualFundStatus: 'failed'};
            })
            .addCase(updateAccountList.pending, (state) => {
                state = { ...state, status: 'loading'};
            })
            .addCase(updateAccountList.fulfilled, (state, action) => {
                state.accounts = action.payload;
                state.status = 'idle';
            })
            .addCase(updateAccountList.rejected, (state) => {
                state = { ...state, status: 'failed'};
            });
    },
});

export const { addAccount, updateAccount, filterAccount } = accountSlice.actions;

export default accountSlice.reducer;
