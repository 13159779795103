import { ChevronLeft } from "@mui/icons-material"
import { AppBar, Avatar, Box, Container, IconButton, Toolbar, Tooltip, } from "@mui/material"
import React from "react"
import Footer from "../components/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

// interface IProps {
//     buttonAction: Function;
//     children: ReactNode;
// }
export default function BackButtonWithFooterLayout({ children, ...props }: any) {

    const navigate = useNavigate();
    const { user } = useAuth();

    return <React.Fragment>
                <AppBar sx={{ bgcolor: "transparent", pt: 1, boxShadow: 'none' }} position="static">
                    <Container maxWidth="xs">
                        <Toolbar disableGutters>
                            <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                <IconButton
                                    sx={{ color: '#000000', pl: 0 }}
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={() => navigate(-1)}
                                    color="inherit"
                                >
                                    <ChevronLeft fontSize="large" />
                                </IconButton>
                            </Box>

                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Notification">
                                    <IconButton onClick={() => navigate('/profile') } sx={{ }}>
                                        <Avatar sx={{ backgroundColor: '#a7dcdc' }}>{user?.initials}</Avatar>
                                    </IconButton>
                                </Tooltip>
                                {/* <NotificationsOutlined fontSize="large" /> */}
                            </Box>

                        </Toolbar>
                    </Container>
                </AppBar>
                <Container sx={{ px: 2}} component="main" maxWidth="xs">
                    <Box py={1} pb={9}>
                        <Outlet />
                    </Box>
                </Container>
                <Footer />
            </React.Fragment>
}